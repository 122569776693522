import React from "react"
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"

const TransferPricingAudit = () => {
  return (
    <PageLayout header={"TRANSFER PRICING AUDIT"}>
      <div className="flex flex-col justify-between align-middle p-6 w-[100%]">
        <div className="flex flex-row  justify-around align-middle overview">
          <div className="flex flex-col justify-between align-middle p-5">
            <h1 className={`my-2 ${h1}`}>
              Transfer Pricing in India – Background & History
            </h1>
            <p>
              Since 1991, with the liberalization of trade and foreign exchange
              policy India has started integrating its economy with global economy.
              This has led to increased cross border flow of goods, services, funds
              and even intangibles. There was a large inflow of Foreign Direct
              Investment (FDI). Monetary controls were relaxed and quantitative
              import barriers were lifted. Obviously, with the growing MNEs
              interested in India, it has become imperative for tax authorities in
              India to take cognizance of transfer pricing issues. It is relevant to
              note that many of the Indian companies have also become large global
              players with major acquisitions in recent past and with overseas
              subsidiaries in many tax jurisdictions
            </p>
            <h1 className={`my-2 ${h1}`}>
              Introduction of Transfer Pricing Regulations in India
            </h1>
            <p>
              The Transfer pricing Regulations (TPR) were introduced in India dive
              the Finance Act, 2001 by substitution of the existing 92 and
              introduction of new sections sections 92A to 92Fin the Income Tax Act
              (‘Act’) and relevant rules 10A to 10E in the Income Tax Rules, 1962.
              The regulations are applicable to relevant international transactions
              entered into from 1st April 2001.
            </p>
            <p className="my-2">
              Before the introduction of the above detailed provisions, the concept
              of Transfer Pricing Laws India was applied under the Act in some
              specified circumstances and in a limited manner. Erstwhile s 92
              provided that if the tax authorities believed that an international
              transaction with a non-resident resulted in less than ordinary profits
              for the resident owing to a “close connection” between the two they
              could re-compute the taxable income of the resident.
            </p>
            <p className="my-2">
              TPR was introduced with a view to provide a detailed statutory
              framework which can lead to computation of reasonable, fair and
              equitable profits and tax in India, in the cases of multinational
              enterprises, and also introduced new s 92A to 92F in the Act, relating
              to computation of income from an international transaction having
              regard to the arm’s length price, meaning of associated enterprise,
              meaning of international transaction, computation of arm’s length
              price, maintenance of information and documents by persons entering
              into international transactions and definitions of certain expressions
              occurring in the said sections.
            </p>
            <p className="my-2">
              The legislative intention, underlying the TPR, is to prevent the
              shifting of profits by manipulating prices charged or paid in
              international transactions, thereby eroding India’s tax base. The
              explanatory memorandum of Finance Bill, 2001 explains that the TPR was
              introduced to curb transfer pricing abuse.
            </p>
            <h1 className={`my-2 ${h1}`}>How to Transfer Pricing Audit in India</h1>
            <p>
              During the last decade, India is now a major opportunity for global
              businesspeople looking to grow a successful business in India.
              Liberalization, booming middle class, and employment and the wages
              growth have made ​​India an appealing destination. Simultaneously,
              creating a company in India means to browse through the various tax and
              legal complexities. And one of the main tax legislation that need to be
              into consideration is the transfer pricing litigation.
            </p>
            <p className="my-2">
              In order to curb the practice of avoiding tax audit by the foreign
              companies in India, a legislation under the name ‘Transfer Pricing
              Regulation’ has been introduced.
            </p>
            <p className="my-2">
              So in a number of articles, Here Sarash Jain & Associates has explored
              various aspects of transfer pricing tax audit. Let us begin with
              understanding the transfer pricing rules in India.
            </p>
            <p className="my-2"></p>
            <h1 className={`my-2 ${h1}`}>
              The following are the important statutes of the law.
            </h1>
            <ul className="list-disc my-2">
              <li>
                Each person or association who has involved in an international
                transaction should maintain an up-to-date record of each transaction
                as prescribed by the legislation.
              </li>
              <li>
                All income acquired by the company by means of any international
                transaction shall be calculated at arm’s length price. There are
                various methods to calculate the arm’s length price, depending on the
                nature and type of the transaction, the nature of the group or the
                association involved, or any other features of the transactions
                involved. These methods are introduced by the Central Board of Direct
                Taxes, generally known as the ‘Board’. Some of them include the
                resale price method, cost plus method, comparable uncontrolled price
                method, and transactional net margin method.
              </li>
              <li>
                If there are two or more appropriate prices assumed for a certain
                transaction, the arm’s length price will be calculated as the average
                of the prices.
              </li>
              <li>
                At the end of a financial year, the person or group involved in an
                international transaction should submit the report of it in Form 3CEB
                under the guidance of a Chartered Accountant. This form has to be
                filed before he files the Income Tax return of the same period.
              </li>
            </ul>
            <p>
              The group or person who does not adhere to these rules is liable to pay
              the penalties as imposed by the Board.
            </p>
          </div>
          <div className="flex flex-col justify-between align-middle p-5">
            <h1 className={`my-2 ${h1}`}>What is a Transfer Pricing Study?</h1>
            <p>
              A transfer pricing study examines the pricing of transactions between
              related two or more associates. By applying and documenting various
              test methods, it is determined whether the transactions are conducted
              under market conditions and survive the scrutiny of the IRS and other
              tax authorities.
            </p>
            <p>
              A study of transfer pricing shall justify how a particular method is
              selected for enterprises and transactions being reviewed.
            </p>
            <h1 className={`my-2 ${h1}`}>
              Transfer Pricing Study for Indian Companies
            </h1>
            <p className="my-">
              All Indian companies are required to analyze their international
              transaction with respect to the Transfer Pricing Regulation and adhere
              to it by maintaining proper transaction records and documents.
            </p>
            <h1 className={`my-2 ${h1}`}>How can SJA Consultants help you?</h1>
            <p className="my-">
              SJA acts as the advisor to your company, especially in matters
              concerning the effective operation of your business in India. We can
              help you in countering the new Transfer Pricing Regulation in a
              cost-effective manner, without consuming much of your time. We provide
              you the appropriate solution after studying your business objectives
              and the nature of transactions that have been carried out.
            </p>
            <p className={`my-2 ${h1}`}>
              The following step-by-step procedures explain our modus operandi.
            </p>
            <ul className="list-disc my-2">
              <li>
                A fact-finding exercise is carried out in order to analyze the
                various functions performed by the organization and the possible
                risks that can be encountered by each activity.
              </li>
              <li>
                Select the appropriate method of transfer pricing and identify the
                parties who have been tested with the particular method.
              </li>
              <li>
                Conduct a survey based on the database available from various
                national and international sources in order to identify the companies
                that can be benchmarked for the selected company and perform a
                financial analysis on the basis of them.
              </li>
              <li>
                Prepare a consolidated report on the basis of the analysis and
                document it appropriately.
              </li>
              <li>
                Issue the report in Form 3CEB as mandated by the Indian Income Tax
                Act, 1961.
              </li>
            </ul>
            <p>
              SJA is also specialized in defending the transfer pricing policy of
              various companies in front of the policy officers and thus counter them
              in an efficient manner.
            </p>
            <h1 className={`my-2 ${h1}`}>
              Transfer Pricing Litigation, Documentation and Study
            </h1>
            <strong>
              Transfer pricing study, litigation and consultants services under the
              Indian Transfer Pricing law By: Sarash Jain & Associates
            </strong>
            <p>
              Sarash Jain & Associates offer consulting on transfer pricing
              documentation in more qualitative and intelligent online study and the
              dispute on the basis of inputs issued by the clients. In addition,
              adequate tax advice will also suggest and consulting on setting ARMs
              length price and adoption of the most appropriate method. This
              documentation differs from across industries and one company to
              another, but in the Laws of Transfer Pricing and concepts. We will be
              providing similar functional elements comparison of publicly available
              databases, both locally and globally, after comparison the FAR
              analysis, based on economic and Indian market conditions. Our study
              involves the analysis of contemporaneous facts and research from public
              databases also to analyze correct Price Comparison of functional
              elements and the like.
            </p>
            <h1 className={`my-2 ${h1}`}>
              Why SJA Consultants for Transfer Pricing Audit and Study
            </h1>
            <p className={`my-2 `}>
              Our best practical{" "}
              <strong>transfer pricing audit services directly </strong>enable us to
              develop more stringent, better quality products, and collaborate more
              effectively with our clients. Because are a company of impartial
              financial professionals, many of the leading law firms in India and
              worldwide also attended us to create this value and sustainable
              solutions to their multinational corporate clients as experts on
              transfer pricing litigation and arbitration.
            </p>
            <p>
              Our consulting team has significant experience to a higher level, and
              work together with multinational clients and their legal and tax
              consultants to deliver the highest standard of case counseling and
              support practice, the application of sophisticated techniques based on
              the economy when it necessary, to address harder issues. Sarash Jain &
              Associates Clients range from some of the biggest known and most
              enterprises in the world for medium enterprises and through involving a
              number of iconic brands.
            </p>
            <h1 className={`my-2 ${h1}`}>
              Transfer Pricing Audit in Delhi, Haryana
            </h1>
            <p>
              SJA’s transfer pricing professionals help taxpayers to overseas
              documentation requirements in India and for the report preparation for
              transfer pricing documentation that analyze the nature of full
              competence in prices between companies. We also help multinationals
              with multiple FATS to prepare the global documentation, provided all
              documentation requirements efficiently and consistently. Our team of
              audit consultants have the expertise to know what the tax officials are
              looking for, and may help you preparing your documents properly the
              first time.
            </p>
          </div>
        </div>
        <p className="my-1">
          Should you know how to carry out the business activities adhering to the
          transfer pricing policy or should you have any query on other related
          procedures, you may contact below details in Delhi, Haryana region of India
          for effective transfer pricing audit, documentation, study, litigation and
          consultants service:-
        </p>
        <div className="my-5">
          <table className=" border border-blue-300table-fixed w-[100%]">
            <thead>
              <tr>
                <th>Sarash Jain, FCA</th>
              </tr>
            </thead>
            <tbody className="border border-blue-300">
              <tr className="border border-blue-300">
                <td className="border border-blue-300 p-3">Mobile</td>
                <td className="border border-blue-300 p-3">+ 91 97297 07024</td>
              </tr>
              <tr className="border border-blue-300">
                <td className="border border-blue-300 p-3">Tel</td>
                <td className="border border-blue-300 p-3">+ 91 130 4013497</td>
              </tr>
              <tr className="border border-blue-300">
                <td className="border border-blue-300 p-3">E-mail</td>
                <td className="border border-blue-300 p-3">snjsonipat@gmail.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </PageLayout>
  )
}

export default TransferPricingAudit
