import React from 'react'
const h1 = "text-xl font-semibold mb-2"


const BranchOffice = () => {
  return (
    <div className="d-flex flex-col justify-center align-middle  px-4 py-3">
    <div className="my-3">
    <p><strong>In case a foreign company</strong> wishes to undertake trading or commercial activities in India without establishing/investing into an Indian company, it may establish a branch office in India, with the prior approval of RBI, for undertaking certain specified activities.
      </p>
      <h3 className={`my-2 ${h1}`}>Scope of activities</h3>
      <p className="my-2">Branch offices are permitted to undertake only those activities, as approved by RBI, that typically enable them to:</p>
      <ul className="list-disc my-2 px-4 py-2">
        <li>Undertake the export and import of goods;</li>
        <li>Render professional or consultancy services;</li>
        <li>Carry out research work in which the parent company is engaged;</li>
        <li>Promote technical and financial collaborations between Indian companies and parent/overseas group companies;</li>
        <li>Represent the parent company in India and act as buying and selling agents;</li>
        <li>Render services in information technology and development of software in India;</li>
        <li>Render technical support to the products supplied by the parent/group companies;</li>
        <li>Operate as a foreign airline/shipping company.</li>
      </ul>
      <p className="my-2">100% FDI is allowed in setting up a stand -alone branch in a SEZ. A branch has to be set up on a stand-alone basis, i.e. such branch office will be isolated and restricted to the SEZ alone and no business activity/transaction will be allowed outside the SEZ (this includes branches/subsidiaries of its parent office in India).Approval process
An application in the prescribed form has to be submitted to RBI for establishing a branch office in India. The lead time for processing a branch office approval typically ranges from four to five weeks, unless the application is referred to the administrative ministry concerned (such as in the case of banking entities) within the Government of India for comments which may lead to an increase in processing time.
As per the provisions of the SEZ Act, no prior approval of RBI is required to set up a branch in a SEZ.</p>
<h3 className={`my-2 ${h1}`}>Remittance facilities</h3>
      <p className="my-2">Branch offices are permitted to undertake only those activities, as approved by RBI, that typically enable them to:</p>
<h3 className={`my-2 ${h1}`}>Remittance facilities</h3>
      <p className="my-2">The RBI approval for establishing a branch office permits the opening of a bank account for meeting expenses related to Indian activities, as well as crediting proceeds/income generated in India. Branches are permitted to repatriate profits generated in India on an ongoing basis, after complying with certain procedural requirements.</p>
<h3 className={`my-2 ${h1}`}>Taxation</h3>
      <p className="my-2">A branch office is considered as an extension of a foreign company in India. Therefore, income earned by the branch office is taxed in India in accordance with the taxation provisions applicable to foreign companies under the Act.</p>
      <p className="mb-2">In case the provisions of a tax treaty between India and the country of which the foreign company is resident, are more beneficial than the Act, then it is open to the foreign company to elect being taxed under the provisions of the relevant tax treaty.</p>
<h3 className={`my-2 ${h1}`}>Exit options</h3>
      <p className="my-2">Closure of a branch office normally involves a time frame of six to eight weeks. An application enclosing the prescribed documentation has to be made to the Central office of RBI.</p>
      <p className="my-2">Apart from obtaining RBI approval for establishing a liaison office, project office/branch office, the foreign company is also required to register with the Registrar of Companies (“ROC”). An application has to be filed in the prescribed form within 30 days of the establishment of the office in India with ROC, pursuant to which ROC would issue a certificate of establishment of place of business in India to the foreign company.</p>
      <p className="my-2">Entry requirements for doing business in India
Public Limited Company</p>
      <p className="my-2">A company that can offer shares to the public is termed as a public limited company. The Companies Act 1956 mandates a list of criteria that have to be met by the public limited companies before they start their business operations in India. A few of these criteria are listed below:</p>
      <ul className="list-disc my-2 px-4 py-2">
        <li>
        It should have at least seven shareholders.
        </li>
        <li>A public company is allowed to start its activities only after procuring the ‘Certificate of Commencement of Business’. The ‘Certificate of Incorporation’ alone will not suffice the purpose.</li>
        <li>The company should release a prospectus or issue a statement to sell its securities.</li>
        <li>It must have at least three directors in its board.</li>
        <li>The company should conduct statutory meeting from time to time.</li>
      </ul>
      <h3 className={`my-2 ${h1}`}>Private Limited Company</h3>
      <p className="my-2">A private limited company is not owned by any governmental body, and it does not offer public shares. The number of shareholders for a private limited company is restricted to a maximum 50, whereas the minimum required is 2. The shareholders, however, do not have the power to transfer or trade their shares publicly.</p>
      </div>
      </div>
  )
}

export default BranchOffice