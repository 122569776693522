import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

const AuditingOverview = () => {
    return (
        <PageLayout header={"AUDITING"}>
            <div className="d-flex flex-col justify-center align-middle">
                <div className="my-3">
                <p>If you own a business, the annual audit can sometimes feel like a time-consuming and expensive process that only benefits the statutory authorities. We make sure all your onerous reporting requirements are met as painlessly as possible- keeping banks, creditors, finance providers, and even the inland revenue happy.</p>
                    <p>But more than that – we aim to offer you the kind of business advice that could help you to run your company efficiently and cost-effectively. We also offer expert corporate tax planning advice, to make sure you’re as tax-efficient as possible.</p>
                </div>
                <div className="my-3">
                <h1 className={`my-2 ${h1}`}>Audit</h1>
                    <p>Auditing Firms can test your performance against industry standards, even against your direct competitors. We’ll review your accounts department and the control measures you currently use, recommending ways to improve. We might discover that your competitors are receiving payment more quickly than you, your stock levels might be higher than the industry norm, or perhaps your company takes longer than average to complete orders and sell finished work or there may be ways to improve your cashflow using some careful tax planning.</p>
                    <p>Our audit is special because of the people who do it – we only recruit the best, and they’re not afraid to get their hands dirty if it means they really get to understand your business. We train all our staff to anticipate client needs, communicate clearly and take ownership of their work. And because we draw on the experience of our senior partners across all disciplines, we take every opportunity to help your business grow.</p>
                    <p>There’s no such thing as ‘just another audit client’ at Sarash Jain & Associates you’re a growing enterprise, and your growth is the focus of our business. So don’t be afraid of your audit – use it.</p>
                    <p>Are you looking for bad credit debt consolidation loans, to consolidate poor credit or other debt then consolidating your debt into one of our cheap loans with lower monthly repayments!</p>
                </div>
                <div className="d-flex flex-col justify-start align-middle">
                        <h3 className={` my-2 ${h2}`}>We provide following Audit Services</h3>
                        <ul className="list-disc my-2">
                            <li>Internal Audit Services / Concurrent Audit Services</li>
                            <li>Management Audit Services</li>
                            <li>Operations and Efficiency Audit Services</li>
                            <li>Special Investigative Audit Services</li>
                            <li>Due Diligence Review</li>
                            <li>Costing and Accounting System Design and Review</li>
                            <li>Compilation of final accounts as per accounting standards of US GAAP</li>
                            <li>Internal Audits</li>
                            </ul>
                            </div>
            </div>
        </PageLayout>
    )
}

export default AuditingOverview
