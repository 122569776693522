import React from 'react'
const h1 = "text-xl font-semibold mb-2"


const LiaisonOffice = () => {
  return (
    <div className="d-flex flex-col justify-center align-middle px-4 py-3">
    <div className="my-3">
      <h3 className={`my-2 ${h1}`}>Starting Business in India</h3>
      <p>
        <strong>A liaison office</strong> is the most basic form of business presence that a foreign company can have in India. Permission to open a liaison office in India is granted by RBI, the apex exchange control authority. Liaison offices are normally established by foreign companies to promote their business interests by spreading awareness of their product(s) and exploring opportunities for business and investment in India. Foreign insurance companies have a general permission to establish a liaison office in India provided they have obtained permission from the Insurance Regulatory Development Authority of India and they comply with certain prescribed conditions
      </p>
      <h3 className={`my-2 ${h1}`}>Scope of activities</h3>
      <p>Under the current exchange control regulations, a liaison office is permitted to:</p>
      <ul className="my-2 list-disc p-5">
        <li>Represent the parent/group companies in India ;</li>
        <li>Promote exports and imports from/to India;</li>
        <li>Promote technical /financial collaborations between parent/group companies and companies in India ;</li>
        <li>Act as a communication channel between parent/group companies and companies in India.Typically, a liaison office is not permitted to</li>
        <li>Earn any income;</li>
        <li>Undertake any industrial, trading or commercial activity;</li>
        <li>Enter into any agreement on behalf of the head office;</li>
        <li>Borrow or lend money for any commercial activity;</li>
        <li>Charge any fee or commission or otherwise earn any income, in respect of liaison activities carried on in India</li>
      </ul>
      <h3 className={`my-2 ${h1}`}>Approval process</h3>
      <p>An application in the prescribed form has to be submitted to RBI for establishing a liaison office in India. The lead time for processing a liaison office approval typically ranges from three to four weeks, unless the application is referred to the administrative ministry concerned within the Government of India for its comments, which may lead to an increase in the processing time.</p>
      <h3 className={`my-2 ${h1}`}>Remittance facilities</h3>
      <p>As stated above, a liaison office cannot earn any income in India (except for interest on surplus funds lying in its local bank account subject to certain conditions). Therefore, all expenses of the liaison office have to be met out of inward remittances from the head office. Any balance in the liaison office account can typically be repatriated, only at the time of closure of the liaison office.</p>
      <h3 className={`my-2 ${h1}`}>Taxation</h3>
      <p>As stated above, liaison offices are not permitted to carry on any industrial, trading or commercial activities, nor to earn any income in India. However, sec 139(1) requires all companies to furnish a return of income. Hence, liaison offices would also be required to file their return of income in India.</p>
      <h3 className={`my-2 ${h1}`}>Exit options</h3>
      <p>Closure of a liaison office normally involves a time frame of five to six weeks. An application enclosing the prescribed documentation is required to be made to the requisite regional office of RBI.</p>
      </div>
      </div>
  )
}

export default LiaisonOffice