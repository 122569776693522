import { Carousel } from "react-responsive-carousel"
import { carouselData } from "../lib/constants"

export default function Hero({ data }) {
  return (
    // <section
    //   className="bg-cover	bg-no-repeat bg-center h-screen"
    //   // style={{
    //   //   backgroundImage:
    //   //     "url(https://marketplace.canva.com/XaqE0/MAE7x9XaqE0/1/s2/canva-business-finance-concept.-MAE7x9XaqE0.jpg)",
    //   // }}
    // >
    <Carousel
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
      showIndicators={false}
      className="carousel"
    >
      {/* <div
        className="absolute top-0 left-0 h-full w-full"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      ></div> */}
      {carouselData.map((item) => (
        <div className=" text-white z-1 relative text-center w-full flex flex-col justify-center items-center carousel__body">
          <div className="w-screen carousel__image">
            <img src={item.image} alt="" className="" />
          </div>
          <div className="absolute p-4 hero__header">
            <h1 className="h1 mb-2 pt-3 slogan" data-aos="fade-up">
              {item.slogan}
            </h1>
            <p
              className="text-xl mb-1 tagline"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {item.tagLine}
            </p>
          </div>
          {/* <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"></div> */}
        </div>
      ))}
    </Carousel>

    // </section>
  )
}
