export default function PageLayout({ header, children }) {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-white py-20 ">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-6">
            <h1 className="h2 text-theme-800">{header}</h1>
          </div>

          <div className="text-theme-500 ">{children}</div>
        </div>
      </div>
    </section>
  )
}
