import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const CompanyFormationOverview = () => {
    return (
        <PageLayout header={"Company Formation Overview"}>
            <div className="flex flex-col justify-between align-middle p-6 w-[100%]" >
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Companies Act</h1>
                    <p>Company Formation in India or registered in India are governed by the Companies Act 2013.</p>
                </div>
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Shareholders and Directors</h1>
                    <ul className="list-disc my-2">
                        <li>Foreign nationals can incorporate company in India and hold foreign equity to the extent of 100%, which is dependent upon sector in which company will operate and is subject to approval from either Reserve Bank of India (RBI) or Foreign Investment Promotion Board (FIPB).</li>
                        <li>Foreign nationals can be director in Indian company but also there is a need to appoint local director to incorporate a company in India.</li>
                    </ul>
                </div>
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Memorandum & Articles of Association</h1>
                    <p>The Memorandum of Association states the main, ancillary / subsidiary and other objects of the proposed company. The Article of Association contains the rules and procedures for the routine conduct of the proposed company. It also states the authorized share capital of the proposed company and the names of its first / permanent directors. After that Memorandum of Association and Article of Association are required to be stamped.
                        A stamp duty is required to be paid on Memorandum of Association and Article of Association. The stamp duty depends on the authorized share capital.</p>
                </div>
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Share Capital</h1>
                    <p>Shares must be expressed in a fixed amount. “No par value” or “bearer” shares are not permitted. Shares to be subscribed must be expressed in Indian rupees.</p>
                </div>
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Accounts & Auditors</h1>
                    <p>Every company is required to appoint an auditor each year at its AGM. An auditor must be qualified by virtue of the Institute of Chartered Accountants of India Act 1949 and completely independent of the company. Audited accounts of the company serve as tool for various stakeholders like creditors, bankers, investors and revenue authorities.</p>
                </div>
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Public Filings</h1>
                    <p>The names and personal particulars of the directors and secretary, register of charges, share capital, registered office address etc. must be filed with the Companies Registry for public inspection upon incorporation and if there is any change thereafter.</p>
                </div>
                {/* <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}></h1>
                </div> */}
                <div className="flex flex-col justify-between align-middle">
                    <h1 className={`my-2 ${h1}`}>Annual Meetings</h1>
                    <p>An annual general meeting (AGM) must be held once in every financial year and not more than 6 months after the end of financial year. However, a company need not hold its first AGM until 9 months of the date of closing of first financial year.</p>
                </div>
            </div>
        </PageLayout>
    )
}

export default CompanyFormationOverview