import React from 'react'
import PageLayout from "../../PageLayout"
const h2 = "font-semibold mb-2"

const CompanyFormationCheckList = () => {
  return (
    <PageLayout header={"Company Formation CheckList"}>
      <div className="flex flex-col justify-between align-middle p-6 w-[100%]" >
        <div className="flex flex-col justify-between align-middle">
          <h2 className={`my-2 ${h2}`}>1. Details of proposed company to be incorporated</h2>
          <ul className="list-disc my-">
            <li>Proposed names of the company in order of preference.</li>
            <li>Main objects of the company.</li>
            <li>Authorized share capital of the company</li>
          </ul>
          <h2 className={`my-2 ${h2}`}>2. Details of Directors ( Provide details of 2 directors in case of private company and 3 directors in case of public company ).</h2>
          <ul className="list-disc my-">
            <li>Complete Name</li>
            <li>Address including (city, state, pincode, country)</li>
            <li>Father’s Name</li>
            <li>Date of Birth</li>
            <li>Nationality</li>
            <li>Occupation</li>
            <li>Photograph of every proposed Directors. (scanned photographs will do)</li>
            <li>Copy of passport as a proof of identity and copy of proof of address (electricity bill/ telephone bill/ bank statement/driving license). (scanned copy will do)</li>
          </ul>
          <h2 className={`my-2 ${h2}`}>3. Details of Shareholders (Provide details of 2 shareholders in case of private company and 7 in case of public company)</h2>
          <strong>a. Individual</strong>
          <ul className="list-disc my-">
            <li>Complete Name</li>
            <li>Address including (city, state, pincode, country)</li>
            <li>Father’s Name</li>
            <li>Date of Birth</li>
            <li>Nationality</li>
            <li>Occupation</li>
            <li>Photograph of every proposed Directors. (scanned photographs will do)</li>
            <li>Copy of passport as a proof of identity and copy of proof of address (electricity bill/ telephone bill/ bank statement/driving license). (scanned copy will do)</li>
          </ul>
          <strong className={`my-2 ${h2}`}>b. Corporate Shareholders</strong>
          <ul className="list-disc my-">
            <li>Copy of Certificate of incorporation</li>
            <li>Copy of Memorandum and Articles of Association</li>
            <li>Board resolution of the existing company authorizing for shareholding in the proposed company.</li>
          </ul>
        </div>
      </div>
    </PageLayout>
  )
}

export default CompanyFormationCheckList
