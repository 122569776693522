// @ts-nocheck
import React from "react"
import { Link } from "react-router-dom"
import { FaXTwitter, FaYoutube, FaFacebook, FaLinkedinIn } from "react-icons/fa6"
import { EMAIL, PHONE_NO, PHONE_NO_1, Socials } from "../lib/constants"
import { newLogo } from "../assets"

const SocialLinks = () => {
  return (
    <div className="flex flex-1 justify-around items-center h-full flex-wrap px-5 pb-1">
      {/* Social links */}
      <div className="flex flex-1 md:gap-8 justify-start text-center flex-wrap md:flex-nowrap items-center py-2">
        <img src={newLogo} alt="" style={{ maxHeight: "25px" }} />
        <div className="flex gap-2 items-center px-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-telephone-fill"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            />
          </svg>
          <a href={`tel:${PHONE_NO}`}>IND {PHONE_NO}</a>
        </div>
        <div className="flex gap-2 items-center px-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            />
          </svg>
          <a href={`tel:${PHONE_NO_1}`}> {PHONE_NO_1}</a>
        </div>

        <div className="flex gap-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
          </svg>
          <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
        </div>
      </div>
      <ul className="flex justify-between align-middle mb-4 md:order-1 md:ml-4 md:mb-0">
        <li className="ml-4">
          <Link
            target="_blank"
            to={Socials.TWITTER}
            className="flex justify-center  items-center my-2 text-theme-600  hover:text-gray-500 rounded-full transition duration-150 ease-in-out"
            aria-label="Twitter"
          >
            <FaXTwitter size={20} color="text-theme-600" />
          </Link>
        </li>
        <li className="ml-4">
          <Link
            target="_blank"
            to={Socials.YOUTUBE}
            className="flex justify-center  items-center my-2 text-theme-600  hover:text-gray-500 rounded-full transition duration-150 ease-in-out"
            aria-label="Twitter"
          >
            <FaYoutube size={20} color="text-theme-600" />
          </Link>
        </li>

        <li className="ml-4">
          <Link
            target="_blank"
            to={Socials.FACEBOOK}
            className="flex justify-center items-center my-2 text-theme-600  hover:text-gray-500 rounded-full transition duration-150 ease-in-out"
            aria-label="Facebook"
          >
            <FaFacebook size={20} color="text-theme-600" />
          </Link>
        </li>

        <li className="ml-4">
          <Link
            target="_blank"
            to={Socials.LINKEDIN}
            className="flex justify-center items-center my-2 text-theme-600  hover:text-gray-500 rounded-full transition duration-150 ease-in-out"
            aria-label="Linkedin"
          >
            <FaLinkedinIn size={20} color="text-theme-600" />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default SocialLinks
