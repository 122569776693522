import React, { useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from "react-toastify"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { collection, addDoc, serverTimestamp } from "firebase/firestore"
import { db, storage } from "../firebaseConfig"
import { spinner } from "../assets"
import { careersTemplateId, publicKey, serviceId } from "../lib/constants"

export default function Career() {
  const navigate = useNavigate()
  const formRef = useRef()
  const [currentFile, setCurrentFile] = useState("")
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    fullName: "",
    designation: "",
    emailAddress: "",
    experience: "",
    gender: "",
    mobileNumber: "",
  })

  const notify = () => {
    toast.info("Your Application has been received :)", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    })
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setFormData({ ...formData, [e.target.name]: value })
  }

  const onFileChangeHandler = (e) => {
    setCurrentFile(e.target.files[0])
  }

  const onHandleUpload = (e) => {
    setLoading(true)
    const storageRef = ref(storage, `/resumes/${currentFile.name}`)
    const uploadTask = uploadBytesResumable(storageRef, currentFile)
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        console.log(percent)
      },
      (err) => {
        const errorMessage = err.message
        console.log(errorMessage)
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          addDoc(collection(db, "applicants"), {
            timestamp: serverTimestamp(),
            fullName: formData.fullName,
            resumeUrl: url,
          })
        })
        setLoading(false)
      }
    )
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    onHandleUpload()

    await emailjs
      .sendForm(serviceId, careersTemplateId, formRef.current, publicKey)
      .then(
        (result) => {
          if (result.status === 200) {
            notify()
            setFormData({
              fullName: "",
              designation: "",
              emailAddress: "",
              experience: "",
              gender: "",
              mobileNumber: "",
              resume: "",
            })
            navigate("/")
          }
        },
        (error) => {
          console.log(error.text)
        }
      )
    setLoading(false)
  }
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-white py-20 ">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-6">
            <h1 className="h2 text-theme-800">Apply Now</h1>
          </div>

          {/* Form */}
          <div className="max-w-sm mx-auto">
            <form
              enctype="multipart/form-data"
              method="post"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"
                    htmlFor="full-name"
                  >
                    Full Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="fullName"
                    value={formData.fullName}
                    type="text"
                    className="form-input w-full text-gray-950"
                    placeholder="First and last name"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                    htmlFor="company-name"
                  >
                    Designation <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="designation"
                    value={formData.designation}
                    type="text"
                    className="form-input w-full text-gray-950"
                    required
                    onChange={handleInputChange}
                    placeholder="Assistant"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-300 text-sm font-medium mb-1"
                    htmlFor="email"
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="emailAddress"
                    value={formData.emailAddress}
                    type="email"
                    className="form-input w-full text-gray-950"
                    placeholder="you@email.com"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"
                    htmlFor="full-name"
                  >
                    Experience <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="experience"
                    value={formData.experience}
                    type="text"
                    className="form-input w-full text-gray-950"
                    placeholder="2 years"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"
                    htmlFor="full-name"
                  >
                    Gender <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="gender"
                    value={formData.gender}
                    type="text"
                    className="form-input w-full text-gray-950"
                    placeholder="Male/Female"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"
                    htmlFor="full-name"
                  >
                    Mobile Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    type="number"
                    className="form-input w-full text-gray-950"
                    placeholder="+91"
                    required
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-4">
                <div className="w-full">
                  <label
                    className="block text-theme-400 text-sm font-medium mb-1"
                    htmlFor="full-name"
                  >
                    Upload Resume <span className="text-red-600">*</span>
                  </label>
                  <input
                    className="text-theme-400 text-sm"
                    type="file"
                    onChange={onFileChangeHandler}
                    required
                  />
                </div>
              </div>
              <div className="text-sm text-theme-500 text-center">
                I agree to be contacted by Sarash Jain and Associates about this
                offer as per their{" "}
                <Link
                  href="#"
                  className="underline text-theme-800 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out"
                >
                  Privacy Policy
                </Link>
                .
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full flex flex-col justify-center align-middle px-3">
                  <button
                    type="submit"
                    className="btn text-white mb-2 bg-theme-600 hover:bg-theme-800 w-full"
                  >
                    Submit
                  </button>
                  {loading && (
                    <div className="flex justify-center">
                      <img src={spinner} alt="Loader" width="100px" height="100px" />
                    </div>
                  )}
                </div>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
