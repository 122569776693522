import { DTA_Countries, limitedAgreementCountries } from "../../../lib/constants"
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

const BusinessTaxationOverview = () => {
  return (
    <PageLayout header={"GENERAL OVERVIEW"}>
      <div className="flex flex-col justify-between align-middle p-6 w-[100%]">
        <div className="">
          <h1 className={`my-2 ${h1}`}>International Tax Treaties</h1>
          <p>
            India has entered into double taxation avoidance agreements (DTAs) with
            the following countries:
          </p>
          <div className="w-[100%]">
            <div className="my-5 w-[100%]">
              <table className=" border border-blue-300table-fixed w-[100%]">
                <tbody className="border border-blue-300">
                  <tr className="border border-blue-300">
                    <td className="border border-blue-300 p-3">
                      {DTA_Countries.slice(0, 18).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {DTA_Countries.slice(19, 36).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {DTA_Countries.slice(37, 54).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {DTA_Countries.slice(55, 72).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              India has limited agreements with the following countries in respect of
              the income of airlines/merchant shipping:
            </p>
            <div className="my-5 w-[100%]">
              <table className=" border border-blue-300table-fixed w-[100%]">
                <tbody className="border border-blue-300">
                  <tr className="border border-blue-300">
                    <td className="border border-blue-300 p-3">
                      {limitedAgreementCountries.slice(0, 4).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {limitedAgreementCountries.slice(5, 9).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {limitedAgreementCountries.slice(10, 13).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                    <td className="border border-blue-300 p-3">
                      {limitedAgreementCountries.slice(14, 17).map((item) => (
                        <li className="list-disc" key={item}>
                          {item}
                        </li>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h1 className={`my-2 ${h1}`}>Existence of Tax credits</h1>
            <p>
              Indian Tax law authorizes the Indian Government to enter into an
              agreement with the government of any other country to grant relief in
              respect of income on which taxes have been paid in both countries, to
              avoid double taxation of income, to exchange information for the
              prevention of evasion or avoidance of income tax and to recover income
              tax.
            </p>
            <p className="my-2">
              The Finance Act 2006 introduced a new sec 90A, whereby any specified
              association in India may enter into agreement with their counterparts
              in the specified territory outside India and the Central Government
              may, by notification in the Official Gazette make such provision as may
              be necessary for adopting and implementing such agreement to grant
              relied in respect of income on which taxes have been paid in both
              countries, to avoid double taxation of Income, to exchange information
              for the prevention of evasion or avoidance of income tax and to recover
              income tax
            </p>
            <p className="my-2">
              Unilateral relief is also available, mainly to persons who are resident
              in India. The relied is allowed in respect of income which has accrued
              outside India where tax is payable both in the foreign country and in
              India. The foreign country must be one with which India has no tax
              treaty and the tax must actually have been paid in that country.
            </p>
            <p className="my-2">
              Indian tax laws do not contain any provision for tax sparing. Double
              tax relief is granted but only with reference to tax actually paid.
              Likewise, tax treaties entered into by India usually do not provide for
              tax sparing.
            </p>
            <h1 className={`my-2 ${h1}`}>Procedures for resolving tax disputes</h1>
            <p className="my-2">
              A taxpayer aggrieved by an order of the assessing officer can appeal
              against the order to the Commissioner (Appeals). If not satisfied, the
              taxpayer can take the dispute to the Income Tax Appellate Tribunal and
              thereafter, to the High Court and Supreme Court. Taxpayers may also
              file a petition for revision before the Commissioner.
            </p>
            <p className="my-2">
              The Income Tax Act 1961 (the “Act”) gives the Commissioner (Appeals)
              and the Appellate Tribunal considerable autonomy and powers to
              determine the procedure to be followed in appeals.
            </p>
            <p className="my-2">
              An Income Tax Settlement Commission has been established to settle
              cases relating to assessment and reassessment. The Income Tax
              Settlement Commission is constituted by the Central Government and it
              consists of a Chairman and as many Vice Chairman and other members as
              the Central Government may think fit for settling a particular case.
              The Chairman and the Vice Chairman function within the Department of
              the Central Government dealing with direct taxes.
            </p>
            <p className="my-2">
              The Appellate Tribunal, consisting of judicial and accountant members,
              is constituted by the Central Government to hear appeals from the
              orders of the assessing officer, Deputy Commissioner (Appeals) and
              Commissioner (Appeals).
            </p>
            <p className="my-2">
              A reference on a question of law may be made to the High Court and, in
              certain cases, directly to the Supreme Court.
            </p>
            <h1 className={`my-2 ${h1}`}>Advance Tax Ruling Scheme</h1>
            <p className="my-2">
              The Advance Tax Ruling Scheme has been introduced to facilitate the
              inflow of foreign investment. The scheme is applicable to
              non-residents. Under the scheme, advance tax ruling can be given on
              questions of law or fact or both in relation to a proposed or concluded
              transaction. In addition to non-residents, residents notified in the
              Official Gazette by the Central Government will also be allowed to seek
              advance tax ruling. Ruling are to be given within a period of six
              months.
            </p>
            <h1 className={`my-2 ${h1}`}>Fiscal Year</h1>
            <p className="my-2">
              The Indian assessment year runs from 1 April of every year to 31 March
              of the next year. Income earned in The “previous year” (the accounting
              year of the assessee) is taxed in each assessment year.
            </p>
            <h1 className={`my-2 ${h1}`}>Method of payment of tax liabilities</h1>
            <p className="my-2">
              Income tax returns, in the prescribed form and verified, are to be
              submitted to the assessing officer by the due date (sec 139 of the
              Income Tax Act):
            </p>
            <p>
              <strong className="text-xs">“Due date” means:</strong>
            </p>
            <h2 className={`my-3 ${h2}`}>(a) where the assessee is-</h2>
            <ul className="list-disc">
              <li> a company;</li>
              <li>
                a person (other than a company) whose accounts are required to be
                audited under the Act or under any other law for the time being in
                force; or
              </li>
              <li>
                a working partner of a firm whose accounts are required to be audited
                under the Act or under any other law for the time being in force.
              </li>
            </ul>
            <span className={`mt-3 ${h2}`}>
              <strong className="text-xs">
                “The 30th day of September of the assessment year”:
              </strong>
            </span>
            <h1 className={`my-2 ${h2}`}>
              (b) In case of any other assessee, the 31st day of July of the
              assessment year. Permanent Establishment (PE)
            </h1>
            <p>
              The permanent establishment concept transaction is executed through the
              internet for years. The concept of PE has formed the basis of
              application of rules relating to sharing of revenues by various tax
              jurisdiction in cross-border transactions. One of the conditions for PE
              is to have a fixed place of or business in any foreign jurisdiction.
              The concept of PE assumes great importance with regard to Double
              Taxation Avoidance Agreements (DTAs). Sections 92, 92A, 92B, 92C, 92D
              and 92E which provide for the computation of income from international
              transactions as envisaged under the DTA, must be construed to include a
              PE. Sub-section (iiia) of sec 92F defines a PE to include “a fixed
              place of business through which the business of the enterprise is
              wholly or partially carried out”. In addition, sub-section (iii)
              defines an enterprise to include a person or a PE of such person and
              includes even those cases where the activity or the business is carried
              on, directly or through one or more of its units or divisions or
              subsidiaries, whether such unit or division or subsidiary is located at
              the same place where the enterprise is located or at a different place
              or places. Taxation of business income under the DTA would be
              applicable only if there is a PE or a “fixed place of business” in the
              source country. Similarly gains arising from the transfer of movable
              property forming part of the business properly of a PE is taxed in the
              country where such PE or fixed base is located.
            </p>
            <h2 className={`my-2 ${h2}`}>
              Some examples of permanent establishments are as follows:
            </h2>
            <p>a. a place of management;</p>
            <p>b. a branch;</p>
            <p>c. an office;</p>
            <p>d. a factory;</p>
            <p>e. a workshop;</p>
            <p>
              f. a mine, oil well or other place of extraction of natural resources.
            </p>
            <p>
              g. a building site or construction or assembly project which exists for
              an agreed period; and
            </p>
            <p>
              h. provision of supervisory activities for a minimum agreed period on a
              building site or construction site or construction or assembly project.
            </p>

            <h1 className={`my-2 ${h1}`}>
              The term “permanent establishment” in generality of such agreements
              does not include:
            </h1>
            <p>
              a. The use of facility solely for the purpose of storage or display of
              goods or merchandise belonging to the enterprise;
            </p>
            <p>
              b. The maintenance of stock of goods or merchandise solely for the
              purpose of storage or display;
            </p>
            <p>
              c. The maintenance of stock of goods or merchandise solely for the
              purpose of processing by another enterprise;
            </p>
            <p>
              d. The maintenance of a fixed place of business solely for the purpose
              of advertising of similar activities which have a preparatory or
              auxiliary character for the enterprise.
            </p>
            <p className="my-2">
              The Supreme Court has started in one of the latest case that circulars
              or instructions issued by the Central Board of Direct Taxes are binding
              on the revenue authorities.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default BusinessTaxationOverview
