import React from 'react'
const h1 = "text-xl font-semibold mb-2"


const ProjectOffice = () => {
  return (
    <div className="d-flex flex-col justify-center align-middle  px-4 py-3">
    <div className="my-3">
    <p><strong>In case a foreign company</strong> wishes to establish a business presence in India for the limited purpose of executing a project, it may establish a project office for its Indian operations. The objective behind establishment of a project office is to enable a foreign company to establish a temporary base in India for executing specific projects/contracts.
      </p>
      <p className="my-2">
      A foreign company may open a project office in India for executing a contract secured by an Indian company without the prior permission of RBI provided the following conditions are satisfied:
      </p>
      <ul className="my-2 list-disc px-5">
        <li>
        The project is funded directly by inward remittance from abroad;
        </li>
        <li>
        The project is funded by a bilateral or multilateral international Financing Agency;
        </li>
        <li>The project has been cleared by an appropriate authority;</li>
        <li>A company or entity in India awarding the contract has been granted term loan by a public financial institution or a bank in India for the project.</li>
      </ul>
      <p className="my-2">In all other cases, prior approval of the RBI is required to establish a project office in India.</p>
      <h3 className={`my-2 ${h1}`}>Remittance facilities</h3>
      <p className='mb-2'>A project office is permitted to open and operate a bank account including a foreign currency account in India. Typically, expenses of the project office in India can be met only out of inward remittances from the head office, or rupee amounts received locally under the approved contract(s).Outward remittances from the bank account are permitted subject to certain compliance requirements.</p>
      <h3 className={`my-2 ${h1}`}>Taxation</h3>
      <p className='mb-2'>A project office is considered as an extension of a foreign company in India. Therefore, income earned by the project office is taxable in India in accordance with the taxation provisions applicable to foreign companies under the Income-tax Act, 1961 (“Act”).</p>
      <h3 className={`my-2 ${h1}`}>Exit options</h3>
      <p className='mb-2'>Being a restricted business presence in India, the process for closure of a project office is straightforward, and normally involves a time frame of five to six weeks. An application enclosing the prescribed documentation has to be made to the regional office of RBI in case the project office was established under the approval route and to the Authorized Dealer in case the project office was established under the general permission.</p>
      </div>
      </div>
  )
}

export default ProjectOffice