import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

const ProhibitedSectors = () =>   {
    return (
        <PageLayout header={"FDI-PROHIBITED SECTORS"}>
            <div className="d-flex flex-col justify-start align-middle">
                <div className="d-flex flex-col justify-center align-middle">
                    <h1 className={h1}>Prohibited sectors</h1>
                    <p>The present policy prohibits FDI in the following sectors:</p>
                    <ul className="list-disc my-2">
                        <li>Gambling and Betting</li>
                        <li>Lottery business (including government/ private lottery, online lotteries etc)</li>
                        <li>Activities /sectors not open to private sector investment (eg, atomic energy /railways)</li>
                        <li>Retails trading (expect single-brand product retailing)</li>
                        <li>Business of chit fund</li>
                        <li>Nidhi company</li>
                        <li>Real estate business or construction of farm houses</li>
                        <li>Trading in transferable development rights (TDRs)</li>
                        <li>Manufacturing of tobacco, cigars, cheroots , cigarallos, cigarettes and other tobacco substitutes</li>
                        <li>Agriculture (excluding floriculture, horticulture, apiculture and cultivation of vegetables and mushrooms under controlled conditions, the development and production of seeds & planting materials, animals husbandry including the breeding of dogs, viniculture & aquaculture under controlled conditions and services related to the agro and allied sector)</li>
                    </ul>
                    <div className="my-5">
                        <h3 className={` my-2 ${h2}`}>Permitted sectors</h3>
                        <p className="my-2">As mentioned above, FDI is permissible in certain with a cap on the maximum permissible foreign holding. Details of same of the sectors in which FDI is subject to sectoral caps is available on following link :</p>
                        <h3 className="my-2">
                            FDI Circular 2014
                        </h3>
                        <h3 className={` my-2 ${h2}`}>Other sector-specific conditions for some of the sectors</h3>
                        <p className="my-2">
                            FDI in various sectors is governed by conditions laid down by the DIPP. These include, among others, minimum capitalization in the case of non-banking finance companies, minimum projects size & capitalization for real estate companies
                        </p>
                        <p className="my-2">Discussed below are certain sectors in which FDI is permitted under the Automatic route/Approval Route subject to certain conditions/restrictions.</p>

                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">i.</label>
                                <h2 className={h2}>FDI in single-brand retail trading</h2>
                            </div>
                            <ul className="list-disc">
                                <li>The government has now allowed FDI of up to 100 percent in the retail trade of single brand products, subjects to the prior approval of the FIPB and the compliance with the following conditions:</li>
                                <li>The products to be sold are of a single brand.</li>
                                <li>The products are sold under the same brand in India, and every other country in which the product is sold.</li>
                                <li>Single brand product retailing only covers products that are branded during the manufacturing process.</li>
                                <li>The foreign investor should own the brand or one non-resident entity, whether owner of the brand or otherwise, and shall be permitted to undertake single brand product retail trading for the specific brand through a legally tenable agreement, with the brand owner for the specific brand for which approval is being sought.</li>
                                <li>If the proposal for FDI exceeds 51 percent of the total capital of the company, at least 30 percent of the products sold have to mandatorily be sourced from Indian small industries (where the total investment in plant and machinery does not exceed US$ 2 million at the time of installation), village and cottage industries, artisans and craftsmen.</li>
                                <li>Retail trading in any form by mean of e-commerce is not permitted</li>
                                <li>The policy requires investors to submit an application to SIA seeking permission from the government for the intended FDI in the retail trade of a single brand product. The application should specifically indicate the product/products categories that are proposed to be sold under a single brand. Any addiction to the product/product categories to be sold under the single brand will requires fresh approval from the government.</li>
                            </ul>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">ii.</label>
                                <h2 className={h2}>Investment in multi-brand retail trading</h2>
                            </div>
                            <ul className="list-disc">
                                <li>Foreign investment in multi-brand retail trading has been permitted up to 51 percent under the approval route in states favoring multi brand retail trading.</li>
                                <li>The investment would be subject to certain conditions. Some of these conditions are as follows:</li>
                                <li>Minimum US$ 100 million to be brought in as FDI;</li>
                                <li>50 percent of the total FDI brought in the first tranche of US$ 100 million to be invested in back end infrastructure; and</li>
                                <li>At least 30 percent of the value of the procurement of manufactured/processed products to be sourced from Indian micro, small and medium industries.</li>
                                <li>Retail sales outlets only in cities with a population of more than 1 million as per the 2011 census.</li>
                                <li>E-commerce not permitted for multi brand retail trading.</li>
                            </ul>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">iii.</label>
                                <h2 className={h2}>FDI in township, housing, built-up infrastructure and construction development projects</h2>
                            </div>
                            <ul className="list-disc">
                                <li>FDI has been allowed in township, housing, built-up infrastructure and construction development projects (including, inter alia, housing, commercial premises, hotels, resorts, hospitals, educational institutional, recreational facilities, and city and regional level infrastructure). The government has allowed to FDI of up to 100 percent under the automatic route in this sector, subject to the following condition:</li>
                                <li>Minimum area to be developed under each projects should be as under:</li>
                                <li>Land area of 10 hectares for serviced housing plots;</li>
                                <li>Built-up area of 50,000 sq. mt. for construction development projects; and</li>
                                <li>For projects that are combination of serviced housing and construction development, the satisfaction of any one of the aforementioned minimum areas condition is sufficient.</li>
                                <li>Wholly owned subsidiaries should have a minimum capitalization of US$10 million and JVs with Indian partner should have a minimum capitalization US$5 million. The funds are required to be brought in within six months from the date that the business commences.</li>
                                <li>The original investment i.e., the entire initial FDI cannot be repatriated for a period of three years from the date that minimum capitalization condition is achieved. If the initial investment is brought in installment/tranches, the three year lock-in period will be applied from the date of receipt of each installment/tranche or the date that the minimum capitalization condition achieved, whichever is later. The foreign investor can however exit earlier than the required three year lock-in period by seeking the prior approval of the government through the FIPB for such an exit.</li>
                                <li>At least 50 percent of the project must be developed within five year from the date of all of the statutory clearances being obtained. The investor is not to be permitted to sell undeveloped plots, i.e., plots where road, water supply, street lighting, drainage, sewerage and other conveniences, as per the prescribed regulation have not been made available.</li>
                                <li>The condition listed above would not apply to hotels and tourism, hospitals, special economic zones, the education sector, old age homes and investment by NRIs.</li>
                            </ul>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">iv.</label>
                                <h2 className={h2}>FDI in the telecom sector</h2>
                            </div>
                            <ul className="list-disc">
                                <li>FDI in telecom services is allowed up to 49 percent under the automatic route and up to 100 percent with the prior approval of the FIPB. Telecom services for this purpose includes basic, cellular, and unfired access services, national/international long distance, V-sat, public mobile radio trunked services, global mobile personal communication services and other value added services.</li>
                                <li>The total composite foreign holding would include investments by FIIs, NRIs, FCCBs, American depository receipt shares and proportionate foreign investment in Indian promoters/investment companies, including their holding companies.</li>
                                <li>While approving investments proposals, the FIPB normally takes note that investment is not coming from countries of concern/unfriendly entities. Certain other restrictions on the transfer of accounting information, user information and details of infrastructure/network diagram have been prescribed that are to be followed by the FIPB while considering the proposals.</li>
                            </ul>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">v.</label>
                                <h2 className={h2}>Investment in asset-reconstruction companies</h2>
                            </div>
                            <ul className="list-disc">
                                <li>Asset reconstruction company (ARC) means a company registered with the RBI under section 3 of the Securitization and Reconstruction of Finance Assets and Enforcement of Security Interest Act, 2002 (SARFAESI Act).</li>
                                <li>A person resident outside India can invest in the equity capital of ARCs registered with the RBI under the government route.</li>
                                <li>Such investments have to strictly be in the nature of FDI. FIIs registered with the SEBI can invest in the security receipt SRs issued by ARCs registered with the RBI. FIIs can acquire up to 74 percent of each tranche of SRs issued, subject to the condition that no single FII investment exceeds 10 percent of the total issue of each tranche of SRs.</li>
                            </ul>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">vi.</label>
                                <h2 className={h2}>Investment in infrastructure companies in the stock markets</h2>
                            </div>
                            <p className="my-1">
                                Foreign investment of up to 49 percent of the paid up capital is permitted in infrastructure companies in the securities markets, namely stock exchanges, depositories and clearing corporations, in compliance with SEBI regulations.
                            </p>
                            <p className="my-1">
                                Within the permitted limit of 49 percent, the FII component cannot exceed 23 percent and the FDI component cannot exceed 26 percent. FDI in such companies is only permitted under the government/approval route. Furthermore, FII investment is permitted only through purchase from the secondary market.
                            </p>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">vii.</label>
                                <h2 className={h2}>Investment in credit information companies (CICs)</h2>
                            </div>
                            <p className="my-1">Foreign investment in CICs is permitted subject to compliance with the Credit Information Companies (Regulation) Act, 2005, and regulatory clearance from the RBI. The aggregate foreign investment in such companies is permitted only up to 49 percent of the paid up the capital (including both FDI FII limits). Investment by SEBI registered FIIs is permitted up to 24 percent (within the overall 49 percent permitted limit) only in CICs listed on the stock exchanges. However, no FII can individually hold, directly or indirectly, more than 10 percent of the equity of the CIC.</p>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">viii.</label>
                                <h2 className={h2}>Investment in commodity exchanges</h2>
                            </div>
                            <p className="my-1">A composite ceiling of 49 percent for foreign investment in commodity exchange is only permitted under the government/approval route, whereas FII purchases are restricted to purchases made via secondary markets. Foreign investment in commodity exchanges is subject to compliance with the requirement that no non-resident investor/entity,
                                including persons acting in concert with them, hold more than 5 percent of the total equity in such a company.</p>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">ix.</label>
                                <h2 className={h2}>Investment in public sector banks</h2>
                            </div>
                            <p className="my-1">FDI and FII investment in nationalized banks are subject to overall statutory limits of 20 percent. The investment is subject to banking companies (acquisition & transfer of undertaking) Acts, 1970/80. The same ceiling also applies to investments in the state bank of India and its associate bank.</p>
                        </div>
                        <div className="my-3 flex flex-col justify-start align-middle px-4">
                            <div className="flex flex-row justify-start align-middle my-3">
                                <label className="mr-2">x.</label>
                                <h2 className={h2}>Investment in print media, dealing with news and current affairs
                                </h2>
                            </div>
                            <ul className="list-disc">
                                <li>FDI under the government /approval route is allowed up to 100 percent in the publication of the facsimile edition of foreign newspapers by the owner of the original foreign newspaper, subject to compliance with the guidelines issued by the ministry of information and broadcasting. Publication can be undertaken only by an entity incorporated or registered in India under the provisions of the Companies Act.</li>
                                <li>Under the government/approval route and subject to compliance with the guidelines issued by the minister of information and broadcasting, FDI and investment by NRIs/PIOs/FII in the publication of India editions of foreign magazines dealing with news and current affairs and newspapers and periodicals dealing with news and current affairs is allowed up to 26 percent.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
    }

export default ProhibitedSectors
