import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"


const ExpatriatesTaxableIncome = () => {
  return (
    <PageLayout header={"TAXABLE INCOME OF EXPATRIATES"}>
<div className="flex flex-col justify-between align-middle p-6 w-[100%]" >
                <div className="my-2">
                    <h1 className={`my-2 ${h1}`}>Taxable services for expatriates</h1>
            <p><strong>Normally, a foreign citizen</strong> who visits India and renders services incurs tax liability on the income earned in India. When an taxation expatriates services works in India, their entire salary-related income is subject to tax, even if it is paid outside India.</p>
            <p className='my-2'>Expenses incurred by an employer in moving an expatriate to and from India and home-leave airfares are not considered taxable income in India. Similarly, payments made in an expatriate’s home country as retirements benefits are also not considered as income earned in India.</p>
            <p>For tax purposes, salary includes base salary, cost-of-living allowances, bonuses, ex-gratia, reimbursement of school fees, utilities, house rent, transportation, tax reimbursements and other payments the company makes on behalf of the employee. Certain in-kind benefits, such as company-lased accommodations, security guards, provision of car with driver, are all taxable on a concessional basis.</p>
            <h1 className={`my-2 ${h1}`}>Taxable services for expatriates</h1>
            <p>Foreigners are entitled to certain special concessions as follows:</p>
            <div className="my-1">
                <p>(1) Remuneration received by a foreigner as an employee of a foreign enterprise for services rendered in India is not subject to Indian income tax, provided:</p>
                <ul className="list-disc my-1">
                    <li>the foreign enterprise is not engaged in any trade or business in India;</li>
                    <li>the foreigner is not present in India for more than 90 days in that year; and</li>
                    <li>the remuneration is not liable to be deducted in computing the employer’s taxable income in India.</li>
                </ul>
            </div>
            <div className="my-1">
                <p>(2) A foreigner ( including a non-resident Indian) who was not resident in India in any of the four financial years immediately preceding the year of arrival in India is entitled to a special tax concession, if provided:</p>
                <ul className="list-disc my-1">
                    <li>the foreigner has specialized knowledge and experience in certain specified industries; a and</li>
                    <li>the individual is employed in any business in India in a capacity in which specialized knowledge and experience are used.</li>
                </ul>
            </div>
            <p>(3) A visiting foreign professor who teaches in any university or educational institution in India is exempt from tax on remuneration received during the first 36 months from the date of arrival in India. However the individual should have not been a resident in India in any of the four financial years immediately preceding the year of arrival in India.</p>
            <p>(4) Salary received by a non-resident foreigner in connection with employment on a foreign ship is exempt from tax if the employee’s stay in India during a year does not exceed 90 days</p>
            <div className="my-1">
                <p>(5) Special exemptions under specified circumstances are available for the following:</p>
                <ul className="list-disc my-1">
                    <li>amounts receivable from a foreign government or a foreign body by a foreigner for undertaking research in India under an approved scheme;</li>
                    <li>remuneration received by employees of a foreign government during training with the Indian government or in an Indian government undertaking; and</li>
                    <li>remuneration received by non-resident expatriates in connection with the filing of motion pictures by non-resident producers.</li>
                </ul>
            </div>
            <div className="my-1">
            <h2 className={`my-2 ${h1}`}>Statutory deductions</h2>
            <p>Under the provisions of the Income Tax Act 1961 every person responsible for paying salary, shall at the time of payment, deduct income tax on the amount payable at the average rate of tax computed on the basis of the rates in force for that particular financial year</p>
            <p className="my-1">This statutory deduction will be made even if the foreigner is simultaneously under more than one employer, or has had successive employment under more than one of them.</p>
            <p>Other deductions from the income of the foreign national include, deductions:</p>
                <ul className="list-disc my-1">
                    <li>for recovery of advances of whatever nature;</li>
                    <li>for recovery of loans made from any fund constituted for the welfare of labour;</li>
                    <li>for damage to or loss of goods entrusted for custody</li>
                    <li>for house accommodation supplied by the employer;</li>
                    <li>required to be made by order of a court; and</li>
                    <li>for subscriptions to and for repayment of, advances from any provident fund to which the provident fund applies etc.</li>
                </ul>
            </div>
            <div className="my-1">
            <h2 className={`my-2 ${h1}`}>Expatriate remuneration</h2>
            <p>Asia has gone from being largely labour intensive to highly a technologically oriented market. It is a great business opportunity for global companies. But more than that, it calls for meticulous attention to expatriate taxation planning. HR practitioners in India, particularly compensation and benefits specialists, would find it useful to understand remuneration practices followed in the rest of the Asian region.</p>
            <p>The remuneration package of the expatriate should normally include base salary, cost-of-living allowances, bonuses, ex-gratia, reimbursement of school fees, health insurance, medical reimbursement, utilities, house rent, transportation, tax reimbursements and other payments the company makes on behalf of the employee. Certain in-kind benefits, such as company-leased accommodations, security guards, provision of car with driver, are generally provided by the employer.</p>
            </div>
                </div>
                </div>
   </PageLayout>
  )
}

export default ExpatriatesTaxableIncome
