import {
  Sarash,
  Vinit,
  Suresh,
  Nipun,
  Karishma,
  taxAdvisory,
  companyRegistration,
} from "../assets"
// import { MdOutlineQueryStats } from "react-icons/md"
// import { ImStatsDots } from "react-icons/im"
import { FaHourglassStart } from "react-icons/fa"
import { TbBuildingCarousel } from "react-icons/tb"
import { LuReceipt } from "react-icons/lu"
import { FaMoneyBillTransfer } from "react-icons/fa6"
import { FaMoneyBillTrendUp } from "react-icons/fa6"
import { GiTakeMyMoney } from "react-icons/gi"
import { BsStack } from "react-icons/bs"
import { AiOutlineAudit } from "react-icons/ai"
import { RiFileTransferFill } from "react-icons/ri"
export const PHONE_NO = "+91-8684893331"
export const PHONE_NO_1 = "0130-4013497"

export const serviceId = process.env.REACT_APP_SERVICE_ID
export const careersTemplateId = process.env.REACT_APP_CAREER_ID
export const queriesTemplateId = process.env.REACT_APP_QUERY_TEMPLATE_ID
export const publicKey = process.env.REACT_APP_PUBLIC_KEY

export const EMAIL = "sjasonipat@gmail.com"
export const services = [
  {
    id: 1,
    title: "Company Formation in India",
    description:
      "We assist our clients in Company Registration, and provide the complete solution for Private limited Company Formation and Company Incorporation in India.",
    itemIcon: BsStack,
  },
  {
    id: 12,
    title: "Starting Business in India",
    description:
      "Business can be setup in India in three steps: Formation of liaison office, Formation of Project office and Formation of Branch office.",
    itemIcon: FaHourglassStart,
  },
  {
    id: 2,
    title: "Auditing and Assurance",
    description:
      "Internal Audit is an independent appraisal function established within an organization to examine and evaluate its activities as a service to the organization.",
    itemIcon: AiOutlineAudit,
  },
  {
    id: 21,
    title: "Corporate Compliance",
    description:
      "Indian companies are governed by The Companies Act 2013, and each company has to comply with various statutory provisions as per different sections of The Act.",
    itemIcon: TbBuildingCarousel,
  },
  {
    id: 3,
    title: "Accounts Outsourcing",
    description:
      "Refinance allows you to take out new personal loans that pay off your current mortgage. Although you are then obligated to make payments on the new top up card loan, your costs typically are lower after refinancing",
    itemIcon: RiFileTransferFill,
  },
  {
    id: 4,
    title: "Business Taxation",
    description:
      "Direct and Indirect Tax consultancy, together with Innovative tax efficient strategies provided by us, form an integral part of viable business decisions. It makes our clients to attain their desired goals.",
    itemIcon: LuReceipt,
  },
  {
    id: 5,
    title: "Transfer Pricing",
    description:
      "Since 1991, with the liberalization of trade and foreign exchange policy, India has started integrating its economy with global economy. This has led to increased cross border flow of goods, services, funds and even intangibles",
    itemIcon: FaMoneyBillTransfer,
  },
  {
    id: 6,
    title: "Taxation of Expatriates",
    description:
      "The employment of expatriates and foreign nationals involves several complex issues. Reason why the Indian economy and market place has generally been less attractive to expatriates and foreign workers.",
    itemIcon: GiTakeMyMoney,
  },
  {
    id: 7,
    title: "Foreign Investment Matters",
    description:
      "Foreign investment in India is primarily governed by the FDI policy formulated by the Secretariat for Industrial Assistance (SIA), the Department of Industrial Policy and Promotion (DIPP), including FEMA matters, FDI, ODI, ECB. Apart from this we assist setting up of liaison/ branch/ project office and repatriation of funds.",
    itemIcon: FaMoneyBillTrendUp,
  },
]
export const URLs = {
  HOME: "/",
  ABOUT_US: "/about",
  SERVICES: "/services",
  NEWS: "/news",
  QUERY_SHEET: "/query",
  CAREER: "/career",
  CLIENTS: "/clients",
  QUERIES: "/query-form",
  PAYMENT: "/payment",
  CONTACT_US: "#footer",
  FDI_OVERVIEW: "/fdi/overview",
  FDI_PROHIBITED_SECTORS: "/fdi/prohibited-sectors",
  FDI_DIRECT_AND_INDIRECT_INVESTMENT: "/fdi/direct-indirect-investment",
  FDI_AUDITING_OVERVIEW: "/fdi/auditing-overview",
  FDI_INTERNAL_AUDIT: "/fdi/internal-audit",
  FDI_SERVICE_TAX_AUDIT: "/fdi/service-tax-audit",

  FDI_TRANSFER_PRICING_AUDIT: "/fdi/transfer-pricing-audit",
  FDI_TRANSFER_PRICING_FAQS: "/fdi/transfer-pricing-faqs",
  FDI_DOMESTIC_TRANSFER_PRICING: "/fdi/transfer-pricing",
  FDI_COMPANY_FORMATION_OVERVIEW: "/fdi/company-formation-overview",
  FDI_COMPANY_FORMATION_FAQS: "/fdi/company-formation-faqs",
  FDI_COMPANY_FORMATION_PROCEDURE: "/fdi/company-formation-procedure",
  FDI_COMPANY_FORMATION_CHECKLIST: "/fdi/company-formation-checklist",
  FDI_BUSINESS_TAXATION_OVERVIEW: "/fdi/business-taxation-overview",
  FDI_BUSINESS_TAXATION_DIRECT_TAXATION: "/fdi/business-taxation-direct-tax",
  FDI_BUSINESS_TAXATION_INDIRECT_TAXATION: "/fdi/business-taxation-indirect-tax",
  FDI_BUSINESS_TAXATION_CAPITAL_GAINS: "/fdi/business-taxation-capital-gains",
  FDI_BUSINESS_TAXATION_GST: "/fdi/business-taxation-gst",
  FDI_TRENDS_IN_INDIA: "/fdi/trends-in-india",
  FDI_TAXABLE_INCOME_OF_EXPATRIATES: "/fdi/taxable-income-of-expatriates",
  FDI_STATUTORY_REQUIREMENT: "/fdi/statutory-requirement",
  FDI_TAX_DUE_DILIGENCE: "/fdi/tax-due-diligence",
  FDI_STARTING_BUSINESS_IN_INDIA: "/fdi/starting-business-in-india",
  FDI_ACCOUNT_OUTSOURCING: "/fdi/account-outsourcing",
  FDI_REGISTRATION_OF_FOREIGN_COMPANIES: "/fdi/registration-of-foreign-companies",
}

//starting business in india, account outsourcing, registration of foreign companies

export const carouselData = [
  {
    id: 111,
    image: taxAdvisory,
    tagLine: "",
    //Here you’ll find accuracy and genuine concern for your needs
    slogan: "",
    //Helping you in every little help
  },
  {
    id: 1,
    image:
      "https://marketplace.canva.com/XaqE0/MAE7x9XaqE0/1/s2/canva-business-finance-concept.-MAE7x9XaqE0.jpg",
    tagLine: "We get your books right the first time, and every time.",
    slogan: "Smart work for smart clients",
  },
  // {
  //   id: 11,
  //   image:ceo,
  //   tagLine:
  //     "What we put into our work is just as important as what we put into our clients",
  //   slogan: "Serving you better.",
  // },

  {
    id: 1111,
    image: companyRegistration,
    //Dedicated accountancy service with personalized support
    tagLine: "",
    slogan: "",
    //Think accounting, Think us
  },
]

export const Socials = {
  TWITTER: "https://twitter.com/sarash_jain",
  FACEBOOK: "https://www.facebook.com/sarashjainasso",
  LINKEDIN: "https://www.linkedin.com/in/sarash-jain-and-associates-a1924b250/",
  YOUTUBE: "https://www.youtube.com/@sarashjain7678",
}

export const ourTeam = [
  {
    id: "1",
    name: "CA. Sarash Jain",
    position: "SENIOR PARTNER",
    description:
      "He is a fellow member of The Institute of Chartered Accountants of India (ICAI). In addition to his expertise in tax audit and taxation related services, he has broad experience in company law matters, GST consultancy, corporate consultancy and internal/concurrent/statutory audits, Business modeling, FDI consultancy, Services and Business Plans. He has expertise in Taxation representative Services. His main motive is 100% client satisfaction. He is so flexible and can work under tight guidelines. He is also an expert to complete his milestone within time limit. He was also Chairman of the Sonepat Branch of NIRC of The Institute of Chartered Accountants of India. (ICAI) for the year 2021-2022",
    image: Sarash,
  },
  {
    id: "11",
    name: "CA Karishma Jain",
    position: "PARTNER",
    description:
      "CA Karishma Jain is the Partner of the firm, and a fellow member of The Institute of Chartered Accountants of India (ICAI). She is also the head in charge of our Delhi based branch. She has good exposure in audits, transfer pricing and international taxation. She specializes in rendering consultancy services in the domain of transfer pricing & also looks after compliance requirements with the Indian Transfer pricing Law. She also handles the audits of subsidiaries of foreign companies operating in India which are engaged in various sectors such as Manufacturing, IT/ITES, infrastructure etc. She is very flexible and can work under tight guidelines. She is very hardworking and completes milestones within the time limit.",
    image: Karishma,
  },
  {
    id: "112",
    name: "Mr Suresh Kumar Jain",
    position: "(RET. BANK OFFICER PSB)",
    description:
      "Has over Forty-Five year experience in all bank audit, bank records, banking compliance in Punjab & Sind Bank.",
    image: Suresh,
  },
  {
    id: "1112",
    name: "Mr Vinit Jain MBA",
    position: "FINANCE",
    description:
      "Has over Twenty years’ experience in Business Analysis, & Appraisal and preparation of Valuation Reports and preparation of rehabilitation and restructuring packages.",
    image: Vinit,
  },
  {
    id: "1136",
    name: "Mr Nipun Jain",
    position: "ADVOCATE",
    description:
      "Has over Five years’ experience in handling legal Compliances, Consultancy on subsidiaries and other related matters. ",
    image: Nipun,
  },
]

export const DTA_Countries = [
  "Armenia",
  "Australia",
  "Austria",
  "Bangladesh",
  "Belarus",
  "Belgium",
  "Brazil",
  "Bulgaria",
  "Canada",
  "China",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hashemite Kingdom of Jordan",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Kazakhstan",
  "Kenya",
  "Korea",
  "Kuwait",
  "Kyrgyz Republic",
  "Libya",
  "Malaysia",
  "Malta",
  "Mauritius",
  "Mongolia",
  "Morocco",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Norway",
  "Oman",
  "Philippines",
  "Poland",
  "Portuguese Republic",
  "Qatar",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "Slovenia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Sweden",
  "Swiss Confederation",
  "Syria",
  "Tanzania",
  "Thailand",
  "Trinidad and",
  "Tobago",
  "Turkey",
  "Turkmenistan",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uzbekistan",
  "Vietnam",
  "Yemen Arab Republic",
  "Zambia",
  "Zealand",
]

export const limitedAgreementCountries = [
  "Afghanistan",
  "Bulgaria",
  "Czechoslovakia",
  "Ethiopia",
  "Iran",
  "Kuwait",
  "Lebanon",
  "Oman",
  "Pakistan",
  "People’s Democratic Republic of Yemen",
  "Russian Federation",
  "Saudi Arabia",
  "Switzerland",
  "United Arab Emirates",
  "Yemen Arab Republic",
]
