import axios from "axios"
import moment from "moment/moment"
import { useEffect, useState } from "react"

function NewsPage() {
  const [loading, setLoading] = useState(false)
  const [news, setNews] = useState(null)

  async function fetchNews() {
    setLoading(true)
    const resp = await axios({
      method: "GET",
      url: ` http://api.mediastack.com/v1/news?countries=in&access_key=${process.env.REACT_APP_NEWS_API_KEY}`,
    })
    setNews(resp.data?.data ?? null)
  }

  useEffect(() => {
    fetchNews()
  }, [])

  useEffect(() => {
    if (news?.length > 0) {
      setLoading(false)
    }
  }, [news])

  return (
    <div className="max-w-6xl mx-auto py-20">
      {loading ? (
        <p className="text-2xl text-center pt-10  text-theme-800">
          Fetching News...
        </p>
      ) : (
        <>
          <h1 className="h2 mb-10 text-theme-800 text-center  ">
            Today's Headlines
          </h1>
          <div className="grid grid-cols-1 py-6 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {news?.map((item) => {
              return (
                <div className="rounded-md overflow-hidden shadow-lg border border-theme-800">
                  <div className="p-2 flex flex-col gap-2">
                    {item?.image && (
                      <img src={item.image} style={{ height: "200px" }} alt="news" />
                    )}

                    <p>{moment(item.publishedAt).format("MMMM D, YYYY h:mm A")}</p>

                    <h2 className="font-semibold text-lg">{item.title}</h2>
                    <p>{item.description}</p>
                    <a
                      className="text-theme-800 font-semibold"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default NewsPage
