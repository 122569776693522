import { Ticks } from "./Ticks"

export default function Features({ className }) {
  return (
    <section className={className}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">.</h2>
            <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div> */}

          {/* Items */}
          <div
            className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none p-3 rounded-lg"
            data-aos-id-blocks
          >
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center  rounded-md border-2  border-theme-500  p-[12px]"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-20 h-20 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-theme-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                <path
                  className="stroke-current text-theme-100"
                  d="M30 39.313l-4.18 2.197L27 34.628l-5-4.874 6.91-1.004L32 22.49l3.09 6.26L42 29.754l-3 2.924"
                  strokeLinecap="square"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                />
                <path
                  className="stroke-current text-theme-200"
                  d="M43 42h-9M43 37h-9"
                  strokeLinecap="square"
                  strokeWidth="2"
                />
              </svg>
              <h4 className="h4 mb-2">Our Vision</h4>
              <p className="text-lg text-theme-400 text-center">
                To be recognized as an accounting firm of choice for providing
                comprehensive financial & professional services to individual
                and business corporations, and professional environment to our
                stakeholders.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center  rounded-md border-2  border-theme-500  p-[12px]"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-20 h-20 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="fill-current text-theme-600"
                  cx="32"
                  cy="32"
                  r="32"
                />
                <path
                  className="stroke-current text-theme-100"
                  strokeWidth="2"
                  strokeLinecap="square"
                  d="M21 23h22v18H21z"
                  fill="none"
                  fillRule="evenodd"
                />
                <path
                  className="stroke-current text-theme-200"
                  d="M26 28h12M26 32h12M26 36h5"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
              <h4 className="h4 mb-2">Our Mission</h4>
              <p className="text-lg text-theme-400 text-center">
                With ‘Quality as Priority’, SJA's ultimate aspiration is to nurture the
                firm with professionalism. And to raise competitive, dynamic and focused
                team leaders in their areas of operation. Providing the best
                opportunity to progress and growth to all those who are
                professionally associated with us and serving their best interest.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center  rounded-md border-2  border-theme-500  p-[12px]"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <svg
                className="w-20 h-20 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-theme-600"
                  width="64"
                  height="64"
                  rx="32"
                />
                <g
                  transform="translate(21 21)"
                  strokeLinecap="square"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                >
                  <ellipse
                    className="stroke-current text-theme-300"
                    cx="11"
                    cy="11"
                    rx="5.5"
                    ry="11"
                  />
                  <path
                    className="stroke-current text-theme-200"
                    d="M11 0v22M0 11h22"
                  />
                  <circle
                    className="stroke-current text-theme-200"
                    cx="11"
                    cy="11"
                    r="11"
                  />
                </g>
              </svg>
              <h4 className="h4 mb-2">Our Values</h4>
              <p className="text-lg text-theme-400 text-center">
                We focus on creating sustainable value growth via innovative
                solutions and unique pathways. Our values are at the heart of our
                business reputation and are essential to our continued success. We
                foster an environment to instill these values in every facet of our
                firm
                <Ticks darkColor={true}>Integrity</Ticks>
                <Ticks darkColor={true}>Accountability</Ticks>
                <Ticks darkColor={true}>Trust</Ticks>
                <Ticks darkColor={true}>Upgradation & Innovation</Ticks>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
