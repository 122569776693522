import Hero from "./hero"
import Features from "./features"
import OurServices from "./OurServices"
import AboutUs from "./AboutUs"
import Clients from "./Clients"
import Testimonials from "./testimonials"

function Home() {
  return (
    <>
      <Hero className="bg-theme-800" />
      <Features />
      <AboutUs className="bg-theme-800" />
      <OurServices />
      <Clients className="bg-theme-800" />
      <Testimonials />
      
    </>
  )
}


export default Home
