import { useState, useEffect } from "react"
import { PHONE_NO } from "../lib/constants"
import ReactWhatsapp from "react-whatsapp";
import useScrollPosition from "@react-hook/window-scroll";


export default function Banner() {
  const [displayScroll, setDisplayScroll] = useState(false)
  const [number] = useState(PHONE_NO);
  const scrollY = useScrollPosition(60 /*frames per second*/);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  // console.log("scroll",window.scrollY)

  useEffect(() => {
    if (scrollY >= 1530) {
      setDisplayScroll(true)
    } else {
      setDisplayScroll(false)
    }

  }, [scrollY])

  return (
    <>
      <div className="fixed bottom-0 right-0 w-full md:bottom-8 md:right-12 md:w-auto z-50 flex items-center gap-3">
        {/* <div className="bg-slate-800 text-slate-50 text-sm p-3 md:rounded shadow-lg flex justify-between"> */}
        <div className="text-slate-500 inline-flex">
          <h5 className="subtitle" style={{ margin: "0", padding: "0" }}>
            <ReactWhatsapp
              number={number}
              message={`Hello sir/madame, good day!! you've reached ${number}`}
            >
              <div className="whatsappPost__button">
                <img
                  src="/whatsapp.png"
                  style={{ height: 50, width: 50 }}
                  alt="whatsapp"
                />
              </div>
            </ReactWhatsapp>{" "}
          </h5>
        </div>

        {
          displayScroll && (
            <div
              className="cursor-pointer w-[40px] h-[40px] flex justify-center items-center"
              onClick={scrollToTop}
              style={{ backgroundColor: "rgba(0,0,0,.45)" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                class="bi bi-caret-up"
                viewBox="0 0 16 16"
              >
                <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
              </svg>
            </div>
          )
        }
      </div>
    </>
  )
}
