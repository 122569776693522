import React from 'react'
import PageLayout from "../../PageLayout"
const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

const DirectAndIndirect = () => {
    return (
        <PageLayout header={"FDI-DIRECT & INDIRECT INVESTMENT"}>
            <div className="d-flex flex-col justify-start align-middle">
                <div className="d-flex flex-col justify-center align-middle">
                    <h1 className={`my-2 ${h1}`}>Computation of foreign investment</h1>
                    <p>To calculate foreign investment, both direct and indirect foreign investment in an Indian company is to be considered.</p>
                    <div className="my-3">
                        <h3 className={` my-2 ${h2}`}>Direct foreign investment</h3>
                        <p>All investment made by a non-resident entity directly is an Indian company is deemed as direct foreign investment, and the entire amount of such investment is to be counted towards foreign investment when calculating a company’s total foreign investment.</p>
                    </div>
                    <div className="d-flex flex-col justify-start align-middle">
                        <h3 className={` my-2 ${h2}`}>Indirect foreign investment</h3>
                        <ul className="list-disc my-2">
                            <li>If any foreign investment is made by an Indian company, which is owned and controlled by resident citizens and/or Indian companies that are, in turn, owned by resident Indian citizens, such investments would not be deemed as indirect foreign investment for the purpose of calculating the total foreign investment in the target company (in which the downstream foreign investment is being made.)</li>
                            <li>If any foreign investment is made by an Indian company that is owned and controlled by non-resident entities, the entire amount of such investment by the Indian company would be the deemed to be indirect foreign investment in the target company (in which the downstream foreign investment is being made.)</li>
                            <li>An Indian company would be deemed to be ‘owned’ by Indian citizen and by Indian companies if more than 50 percent of equity interest in the company is beneficially owned by resident Indian citizens and Indian companies that are, in turn, ultimately owned and controlled by resident Indian citizens.</li>
                            <li>Control has been defined as the right to appoint the majority of directors or to control management and policy decisions, including by virtue of their shareholding or management rights or shareholders or agreement or voting agreements.</li>
                            <li>If any foreign investment is made by a WOS (100 percent subsidiary) of an operating-cum-investing company/investing company, the indirect foreign investment computed with regards to the target company (in which the downstream foreign investment is being made) would be limited to be amount of foreign investment in the operating-cum-investing company/ investing company, i.e., the downstream investment would be mirror image of the holding company.</li>
                            <li>Downstream investment by an Indian company, which is owned and /or controlled by non-resident entity/ies, in another Indian company would be in accordance/compliance with relevant sectoral conditions on entry route, conditionality’s and caps with regards to the sectors in which the latter Indian company is operating.</li>
                            <li>With regards to the information and broadcasting sector and the defense sector, there are certain other additional considerations that need to be complied with.</li>
                            <li>The foreign investment calculation methodologies mentioned above are applicable in business sector that are governed specifically by a state statue or rules (for instance, the insurance sector is governed by the IRDA regulations.)</li>
                            <li>Where company B invests 26 percent in company A, the indirect foreign investment in company A would be taken as 26 percent.</li>
                            <li>Where company B invests 80 percent in company A, the indirect foreign investment in company A would be taken as 80 percent.</li>
                            <li>Where company A is a 100 percent subsidiary of company B, indirect foreign investment in company A would be taken as 75 percent.</li>
                        </ul>
                    </div>
                    <div className="d-flex flex-col justify-start align-middle">
                        <h3 className={` my-2 ${h2}`}>Pricing of shares issued by an Indian company</h3>
                        <ul className="list-disc my-2">
                            <li>The price of shares issued to persons resident outside India under the FDI policy is decided as follows:</li>
                            <li>Where the shares are of an Indian listed company the price shall not be less than the price at which a preferential allotment of shares can be made under the SEBI guidelines as applicable provided that the same is determined for such duration as specified there in, preceding the relevant date which shall be the date of purchase or sale of shares.</li>
                            <li>Fair value to be determined by a SEBI- registered category I- merchant banker or a chartered accountant as per the discounted free cash flow method, where the shares are not listed on any recognized stock exchange in India.</li>
                            <li>With respect to unlisted shares issued to person outside India under a rights issue, the following specific conditions apply:</li>
                            <li>If the price being offered to resident shareholder is less than the price calculated as per the discounted cash flow method, the price of shares issued to nonresident shareholders cannot be lower than the price computed as per the discounted cash flow method.</li>
                            <li>If price being offered to resident shareholders is more than the price calculated as per the discounted cash flow method the price of share issued to nonresident shareholders cannot be less than the price offered to resident shareholders.</li>
                        </ul>
                    </div>
                </div>
            </div>

        </PageLayout>
    )
}


export default DirectAndIndirect