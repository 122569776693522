import { Ticks } from "./Ticks"

export default function Clients({ className }) {
  return (
    <section className={"md:min-h-screen " + className}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 text-white md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">OUR CLIENTS</h2>
            <p className="text-xl text-gray-200">
              Our client list includes domestic and international entities of various
              sizes from different industries. Our team of experienced professionals
              provide financial solutions in a manner where client satisfaction is
              top priority. We are an accounting and auditing firm in India and we ensure
              confidentiality and professional ethics, names and nature of business
              of clients are not provided on the website . We have serviced clients
              from following industries:
            </p>
          </div>

          <div className="max-w-sm mx-auto grid gap-2 grid-cols-2  lg:grid-cols-4 items-start lg:max-w-none">
            <Ticks>Foreign companies having subsidiary in India</Ticks>
            <Ticks>
              Foreign Companies having Branch / Liaison Offices in India.
            </Ticks>
            <Ticks>Non- Resident Indians</Ticks>
            <Ticks>Automobiles and Auto Ancillaries</Ticks>
            <Ticks>Chemicals</Ticks>
            <Ticks>Consumer Durable</Ticks>
            <Ticks>Consultancy Support</Ticks> <Ticks>Education & Welfare</Ticks>
            <Ticks>Electronics</Ticks> <Ticks>Energy</Ticks>
            <Ticks>FMCG</Ticks>
            <Ticks>Food Processing</Ticks>
            <Ticks>Infrastructure</Ticks>
            <Ticks>Manufacturing</Ticks>
            <Ticks>Media</Ticks>
            <Ticks>Pharmaceuticals</Ticks>
            <Ticks>Real Estate & Construction</Ticks>
            <Ticks>Retailing & Distribution</Ticks>
            <Ticks>Software & Information Technology</Ticks>
            <Ticks>Telecommunications</Ticks>
            <Ticks>Trading</Ticks>
            <Ticks>Travel, Tourism & Leisure</Ticks>
          </div>
        </div>
      </div>
    </section>
  )
}
