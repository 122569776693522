import PageLayout from "../../PageLayout"

const h1 = "text-xl font-semibold mb-2"
const h2 = "font-semibold mb-1"

function Overview() {
  return (
    <PageLayout header={"FOREIGN INVESTMENT APPROVALS OVERVIEW"}>
      <div className="flex flex-col justify-between align-middle p-6 w-[100%]" >
        <div className="flex flex-row  justify-around align-middle overview">
          <div className="flex flex-col justify-start align-middle p-5  w-[100%]">
            <h1 className={`my-2 ${h1}`}>Foreign Investment Approvals</h1>
            <p className="my-2"> <strong>Foreign investment in India</strong> is primarily governed by the FDI policy
              formulated by the secretariat for industrial assistance (SIA), the Department
              of Industrial policy and promotion (DIPP), the foreign investment promotion
              board (FIPB) and foreign exchange regulations, which are governed by the RBI.
              Under the present policies and regulations, foreign investment in India is
              possible through the following avenues:</p>
            <p className="my-1"><strong>A</strong>. As FDI; </p>
            <p className="my-1"><strong>B</strong>. By FIIs, directly, via
              the Portfolio investment scheme(PIS);</p>
            <p className="my-1"><strong>C</strong>. By NRIs/persons of Indian origin (PIO), directly and indirectly, via the PIS;</p>
            <p className="my-1"><strong>D</strong>. By qualified foreign
              investors, via the PIs; and</p>
            <p className="my-1"><strong>E</strong>. By foreign venture capital investors (FVCIs)</p>

            <h1 className={`mb-2 ${h1}`}>FDI</h1>
            <span className={`my-b-2 text-xs ${h2}`}>
              <strong>A. Foreign Direct Investment</strong>
            </span>
            <p> In the wake of liberalization in 1980s and the introduction of the new industrial policy of 1991, substantial policy changes were made to pull down administrative barriers to allow for the free flow of foreign capital and international trade. The FDI regime has been progressively liberalized, largely by removing restrictions on foreign investment and simplifying procedures. As a result, among the emerging economies, India has one of the most liberal and transparent foreign investment regimes.</p>
            <h1 className={`my-b ${h2}`}>FDI Policy</h1>
            <p>The government of India releases a compendium of FDI policy every six months. Foreign investment in India can be made either through the automatic route or the approval route</p>
            <h1 className={`my-b ${h2}`}>Automatic Route</h1>
            <p>Under the automatic route, no prior regulatory approval is required from either the RBI or FIPB. Under this route, investors are required to notify the concerned regional office of the RBI within 30 days of receiving investment money in India and to file the required documents and details of the shares allotted, with the same regional office, within 30 days of issuing such shares to the respective foreign investors.</p>
            <h1 className={`my-b ${h2}`}>Approval/Government route</h1>
            <p>FDI in business sectors not covered under the automatic route requires prior approval from the Government of India. Applications for foreign investments that need prior governmental approval are required to be submitted to the FIPB.</p>
            <h1 className={`my-b ${h2}`}>Foreign persons who can invest in India under the FDI regime</h1>
            <ul className="my-2 list-disc">
              <li>A non-resident entity (other than a citizen of Pakistan or Bangladesh or an entity incorporated in Pakistan or Bangladesh) can invest in India subject to compliance with the extent FDI regulations.</li>
              <li>A person who is a citizen of Bangladesh or Pakistan or an entity incorporated in Bangladesh or Pakistan can invest in India under the FDI provisions, subject to receiving the prior approval of the FIPB.</li>
            </ul>
            <p></p>
            <h1 className={`my-b ${h2}`}>Entities into which FDI can be made under the FDI regime</h1>
            <h5 className="text-xs"><strong>a. FDI in an Indian company</strong></h5>
            <p>This is subject to sectoral caps, pricing conditions and other conditions.</p>
            <h5 className="text-xs"><strong>b. FDI in partnership firms/proprietary concern</strong></h5>
            <ul className="list-disc my-2">
              <li>NRIs and PIOs can invest in the capital of the partnership firm or proprietary concern in India, on a non- repatriation basis, in accordance with the following conditions:</li>
              <li>The firm or proprietary concern is not engaged in any agriculture/plantation, real estate or print media business.</li>
              <li>Any amount so invested it brought in by inward remittances or out of an NRE/FCNR (B)/ NRO account maintained with an authorized dealer (AD) or other authorized banks.</li>
              <li>Any amount so invested cannot be remitted outside India.</li>
              <li>NRIs and PIOs may, however, seek the prior approval of the RBI for investment in partnership firms or proprietary concern with an option to repatriate funds out of India and the RBI, in consultation with the, government will decide on each application based upon the merits of the specific case.</li>
              <li>A non- resident other than an NRI or a PIO can apply and seek the approval of the RBI to invest in the capital of partnership firms/proprietary concerns and the RBI, in consultation with the government will decide on each application based upon the merits of the specific case</li>
            </ul>
          </div>
          <div className="flex flex-col justify-between align-middle p-5  w-[100%]">
            <h1 className={`my-b ${h2}`}>FDI in limited liability partnerships (LLPs)</h1>
            <ul className="my-2 list-disc px-4 py-2">
              <li>
                Under the government/approval route, FDI is permitted in LLPs operating in business sector in which 100 percent FDI is allowed under the automatic route, subject to the condition that there are no FDI linked performance conditions.
              </li>
              <li>LLPs with FDI are not permitted to operate agriculture/plantation activity and a real estate or print media business.</li>
              <li>An Indian company with FDI is permitted to make further downstream investments in an LLP if both the company and the LLP are operating in business sector in which 100 percent FDI is allowed under the automatic route, subject to the condition that there are no FDI linked performance conditions. However, LLPs with FDI are not permitted to make further downstream investment</li>
              <li>Foreign investment in LLPs are permitted only as cash consideration received by inward remittances, through normal banking channels or by debit to NRE/FCNR accounts maintained by an ad or authorized bank, expect in the case of conversation of an existing company into an LLPs.</li>
              <li>FVCIs and FIIs are not allowed to invest in LLPs, and LLPs are not permitted to avail of external commercial borrowing (ECBs)</li>
              <li>Conversation of a company with FDI into a LLPs is allowed only if the above conditions are met and with prior approval of the FIPB.</li>
            </ul>
            <h1 className={`my-b ${h2}`}>Types of instruments available for making FDI</h1>
            <ul className="my-2 list-disc px-4 py-2">
              <li>Indian companies can issue equity shares, fully compulsorily & mandatorily convertible debentures and fully compulsorily & mandatorily convertible preference shares, subject to the following:</li>
              <li>Compliance with pricing guidance’s and valuation norms prescribed under the FEMA;</li>
              <li>Determination of the price/conversation formula for convertible instruments upfront at the time of the issue; and</li>
              <li>The price of convertible instruments at the time of conversation cannot be less than the fair value determined as per the FEMA guidance at the time of issue.</li>
              <li>The RBI has recently specified amendments with respects to put and call options. It has clarified that shares or convertible debentures containing an optionality clause but without an option to exit at an assured price shall be eligible instruments.</li>
              <li>The instruments should be subject to a minimum lock in of 1 year or as prescribed, whichever is higher.</li>
              <li>Exit in the case of a listed company is at the market price determined on the recognized stock exchange.</li>
              <li>In case of equity shares of unlisted companies exit is at a price not exceeding that arrived at on the basis of returns on equity as per last audited balance sheet.</li>
              <li>In case of preference shares or debentures, exit is at a price worked out as per any internationally accepted pricing methodology at the time of exit, duly certified by a chartered accountant or SEBI registered merchant banker</li>
              <li>An Indian company can also raise funds from abroad through the issue of foreign currency convertible bond (FCCBs) and depository receipts in accordance with the relevant regulations and guidelines issued by the government of India.</li>
              <li>Other type of instruments such as non-convertible or optionally convertible preference shares are treated as debt and therefore have to follow the ECBs regulations issued by the RBI.</li>

            </ul>
            <h1 className={`my-b ${h2}`}>Prior approval of FIPB</h1>
            <ul className="my-2 list-disc px-4 py-2">
              <li>Prior approval of the FIPB is also required in the following cases:</li>
              <li>Foreign investment exceeding 24 percent of the equity capital of an industry undertaking that is not a micro or small scale enterprise (MSE) but manufactures items reserved exclusively for the MSE sectors.</li>
              <li>Foreign investment in an Indian company engaged only in the activity of investing in the capital of other Indian companies, regardless of the amount or extend of the foreign investment; and</li>
              <li>Infusion of foreign investment in an Indian company that does not have any operations or any downstream investments, regardless of the amount or extend of the infusion.</li>
              <li>An application for investment under the approval route should be made to the FIPB/Ministry of Finance.</li>
              <li>This application can be made on plain paper or online by either the investment company or by the foreign investor. It will however, be necessary to provide the full details of the proposal to the FIPB.</li>
              <li>Recommendation of the FIPB in respect of proposal falling in the non- automatic route and involving an investment of Rs 12,000 million are submitted by the FIPB to the cabinet committee on Economic Affairs for further approval.</li>
              <li>Application for the approval of FDI proposals, along with the FIPB, can also be submitted to the Department of Economic Affairs (DEA) and the ministry of finance. However, in the case of NRI investments, Export oriented units (EOUs) and retail trading (single branded products), the applications are supposed to be submitted to the SIA of DIPP. Application can also be submitted to Indian mission abroad, which in turn, will forward them to the DEA for further processing. Approval is granted by the FIPB on an individual basis, after examining the proposal for investment. Prescribed fillings are then required to be carried out under the prior approval route.</li>
            </ul>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default Overview
