import Clients from "./Clients"
import Testimonials from "./testimonials"

const ClientsPage = () => {
  return (
    <>
      {/* <Hero className="bg-theme-800" /> */}
      <br />
      <br />
      <br />
      <Clients className="bg-theme-800" />
      <Testimonials />

    </>
  )
}

export default ClientsPage